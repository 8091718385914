import React from 'react';
import type { FC, ReactNode, Dispatch, SetStateAction } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { B400, N0 } from '@atlaskit/theme/colors';
import { overflowShadow, shadowClassNames as shadows } from '@atlaskit/editor-common/ui';
import type { OverflowShadowProps } from '@atlaskit/editor-common/ui';

type CardStripComponentProps = {
	cards: ReactNode[];
	cardIds: string[];
	selectedHeroCardIndex: number;
	setSelectedHeroCardIndex: Dispatch<SetStateAction<number>>;
} & OverflowShadowProps;

type CardStripWrapperProps = {
	showLeftBlur: boolean;
	showRightBlur: boolean;
};

const commonBlurStyles = {
	position: 'absolute',
	pointerEvents: 'none',
	content: '',
	top: 0,
	width: '60px',
	height: '100%',
	zIndex: 1,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardStripWrapper = styled.div<CardStripWrapperProps>({
	overflow: 'hidden',
	position: 'relative',
	width: '100%',
	display: 'flex',
	// Show and hide pseudo-elements that add blur at the start(::before) and end(::after) of the container
	// depending on scroll position.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...commonBlurStyles,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ showLeftBlur }) => (showLeftBlur ? 'block' : 'none'),
		left: 0,
		backgroundImage: `linear-gradient(
      to left,
      transparent 10%,
      ${token('elevation.surface', N0)}
    )`,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...commonBlurStyles,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ showRightBlur }) => (showRightBlur ? 'block' : 'none'),
		left: 'calc(100% - 60px)',
		backgroundImage: `linear-gradient(
      to right,
      transparent 10%,
      ${token('elevation.surface', N0)}
    )`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardStripOverflowWrapper = styled.div({
	display: 'flex',
	flexWrap: 'nowrap',
	alignItems: 'center',
	overflow: 'auto',

	justifyContent: 'safe center',
	width: '100%',

	// hiding scroll bar for all browsers
	scrollbarWidth: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar': {
		display: 'none',
	},

	// Fixes issue with a small gap showing on the left and right edges, messing up blur.
	border: `${token('border.width', '1px')} solid transparent`,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled.div<{
	isLastCard: boolean;
}>({
	// can't use gap due to before/after shadow setinels also
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ isLastCard }) =>
		isLastCard ? token('space.0', '0px') : token('space.150', '12px'),
	// Margins on the smart cards make the focus ring on any container but the smart card's container looks off.
	// Hiding focus ring on the container and outlining the children, {smart/error}-cards instead.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus-visible': {
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus-visible div[data-smart-link-container], :focus-visible .link-card-small-error-state': {
		outline: `${token('border.width.outline', '2px')} solid ${token('color.border.focused', B400)}`,
	},
});

const i18n = defineMessages({
	cardLabel: {
		id: 'custom-sites-extensions.carousel.card-strip.card.label',
		defaultMessage: 'slide {positionInSet} of {setSize}',
		description: 'Accessibility label text that is used for each card in the card strip',
	},
});

export const CardStripComponent: FC<CardStripComponentProps> = ({
	cards,
	cardIds,
	selectedHeroCardIndex,
	setSelectedHeroCardIndex,
	handleRef,
	shadowClassNames,
}) => {
	const intl = useIntl();

	const handleSelectCard = (event: React.MouseEvent<HTMLDivElement> | undefined, index: number) => {
		event?.stopPropagation();
		setSelectedHeroCardIndex(index);
	};

	const handleKeyDownAsButtonClick = (
		event: React.KeyboardEvent<HTMLDivElement>,
		index: number,
	) => {
		event.stopPropagation();

		// Keyboard support for button: https://www.w3.org/TR/wai-aria-practices/examples/button/button.html
		// event.key spec: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
		const ACTIVATE_BUTTON_KEYS = ['Enter', 'Spacebar', ' '];

		if (ACTIVATE_BUTTON_KEYS.includes(event.key)) {
			event.preventDefault();
			handleSelectCard(undefined, index);
		}
	};

	return (
		<CardStripWrapper
			ref={handleRef}
			showLeftBlur={!!shadowClassNames?.includes(shadows.LEFT_SHADOW)}
			showRightBlur={!!shadowClassNames?.includes(shadows.RIGHT_SHADOW)}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<CardStripOverflowWrapper className="card-strip-overflow" role="group">
				{cards.map((card, index) => (
					<Card
						key={cardIds[index]}
						tabIndex={0}
						data-testid="card-strip-card"
						role="button"
						aria-current={selectedHeroCardIndex === index}
						aria-label={intl.formatMessage(i18n.cardLabel, {
							positionInSet: index + 1,
							setSize: cards.length,
						})}
						onClick={(event) => handleSelectCard(event, index)}
						onKeyDown={(event) => handleKeyDownAsButtonClick(event, index)}
						isLastCard={index === cards.length - 1}
					>
						{card}
					</Card>
				))}
			</CardStripOverflowWrapper>
		</CardStripWrapper>
	);
};

export const CardStrip = overflowShadow(CardStripComponent, {
	overflowSelector: '.card-strip-overflow',
	useShadowObserver: true,
});
